import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PageHeader from 'components/common/PageHeader';
import CoreTrainingSetup from './CoreTrainingSetup';
import SubSkillCheckList from './SubSkillCheckList';
import { useSearchParams } from 'react-router-dom';
import BasicTemplate from 'components/Toast/BasicTemplate';
import TrainingRecordInfo from './TrainingRecordInfo';
import {
  post,
  get,
  getByParam,
  patch
} from 'services/skill-matric-services/smTrainingRecordService';

import { patch as patchSubSkillTrainingCheck } from 'services/skill-matric-services/smSubSkillTrainingRecordService';

export default function TrainingRecordManager() {
  const [params] = useSearchParams();
  const [filter, setFilter] = useState({ startDate: new Date() });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (params.has('id')) {
      const id = params.get('id');
      get(id).then(res => {
        setFilter({ ...filter, ...res.data });
      });
    } else if (
      params.has('skillLevelNo') &&
      params.has('traineeId') &&
      params.has('areaId')
    ) {
      const skillLevelNo = params.get('skillLevelNo');
      const traineeId = params.get('traineeId');
      const areaId = params.get('areaId');
      getByParam({
        skillLevelNo, traineeId, areaId
      }).then(res => {
        setFilter({ ...filter, ...res.data });
      });
    }
  };

  const handleOutSubChecks = subSkillTrainingChecks => {
    console.log(subSkillTrainingChecks);
    setFilter({ ...filter, subSkillTrainingChecks });
  };

  const checkRequired = () => {
    let valid = true;
    console.log(filter);
    if (!filter.sectionId) {
      toast.error(<BasicTemplate text="Please enter a Section to continue." />);
      valid = false;
    }
    if (!filter.areaId) {
      toast.error(<BasicTemplate text="Please enter a Area to continue." />);
      valid = false;
    }
    if (!filter.trainee) {
      toast.error(<BasicTemplate text="Please enter a Trainee to continue." />);
      valid = false;
    }
    if (!filter.skillLevelId) {
      toast.error(
        <BasicTemplate text="Please enter a Skill Level to continue." />
      );
      valid = false;
    }

    return valid;
  };

  const handeSubmit = async () => {
    if (!checkRequired()) {
      return;
    }
    if (filter?.id > 0) {
      await patch(filter?.id, filter);
      const subSkillCheckParam = filter?.subSkillTrainingChecks?.map(i => {
        return {
          ...i,
          subSkillId: i.id
        };
      });
      await patchSubSkillTrainingCheck(filter.id, subSkillCheckParam);

      fetchData();
    } else {
      const res = await post(filter);
      window.open(
        '/hr/skill-matrix/section/skills/core-training-record?id=' +
          res?.data.id,
        '_blank'
      );
    }
    toast.success(<BasicTemplate success={true} text="Successfully saved!" />);
  };

  const handleFilterChange = (key, value) => {
    switch (key) {
      case 'sectionId':
        setFilter({ ...filter, sectionId: value.id, areaId: null });
        break;

      case 'areaId':
        setFilter({ ...filter, areaId: value.id, skillCriteriaId: null });
        break;
      case 'skillActivityId':
      case 'skillLevelId':
        filter[key] = value.id;
        setFilter({
          ...filter,
          skillCriteriaId: null,
          skillCriteria: null
        });
        break;
      case 'trainee':
        setFilter({ ...filter, trainee: value.userId });
        break;
      case 'department':
        setFilter({ ...filter, department: value.id });
        break;
      default:
        filter[key] = value;
        setFilter({ ...filter });
        break;
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <PageHeader
            title={'Core Training'}
            description={``}
            className="mb-3"
            col={{ lg: 12 }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color="primary" onClick={handeSubmit}>
                Save
              </Button>
            </div>
          </PageHeader>
        </Col>
        <Col xs={12}>
          <CoreTrainingSetup
            formData={filter}
            handleFilterChange={handleFilterChange}
          />

          <Row className="g-3 mb-3">
            <Col lg={3}>
              {filter?.id && (
                <TrainingRecordInfo
                  filter={filter}
                  handleFilterChange={handleFilterChange}
                />
              )}
            </Col>
            <Col lg={9}>
              {filter?.id > 0 && (
                <SubSkillCheckList
                  trainingId={filter?.id}
                  trainee={filter?.trainee}
                  skillCriteriaDes={filter?.skillCriteria?.description}
                  skillLevelId={filter?.skillLevelId}
                  skillActivityId={filter?.skillActivityId}
                  areaId={filter?.areaId}
                  outSubChecks={handleOutSubChecks}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
