import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { search as searchSkillMatrix } from 'services/skill-matric-services/smSkillMatrixService';
import PropTypes from 'prop-types';
import SkillMatrixTable from './SkillMatrixTable';
import { getUsersWithDepartments } from 'services/userServices';
import SkillMatrixTableHeader from './SkillMatrixTableHeader';
// import SkillMatrixFilter from './SkillMatrixFilter';
const RunningSkillMatrix = ({ meetingId }) => {
  const defaultRQ = { ...new BaseTableRQ(1, 2147483647), meetingId: meetingId };
  const [ogiginSkillMatrix, setOginginSkillMatrixs] = useState([]);
  const [skillMatrixs, setSkillMatrixs] = useState([]);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });
  const [isFilter, setIsFilter] = useState(false);
  const [searchUIParam, setSearchUIParam] = useState({});

  useEffect(() => {
    setIsFilter(false);
  }, []);

  // useEffect(() => {
  //   searchKeyword(keyword);
  // }, [keyword]);

  const searchUI = ({keyword, department}) => {
    console.log(keyword,department)
    let searchData = ogiginSkillMatrix;
    console.log(keyword)
    if(keyword) {
      searchData = searchData.filter(i =>
        i.name.toLowerCase().includes(keyword.toLowerCase())
      );
    }
    console.log(department)
    if(department) {
      searchData = searchData.filter(i =>
        i.departmentId.toString() == department.id.toString()
      );
    }
    
    
    setSkillMatrixs(searchData);
  } 

  useEffect(() => {
    loadSkillMatrix(defaultRQ);
  }, []);

  const loadSkillMatrix = async pagingRq => {
    const res = await searchSkillMatrix(pagingRq);
    const usersRes = await getUsersWithDepartments();
    const data = usersRes.map(u => {
      let rs = { ...u, trainee: u.userId };
      const items = res.items.filter(i => i.trainee == u.userId);
      if (items.length > 0) {
        items.forEach(x => {
          rs[x.areaId?.toString()] = x.level
        });
      }
      return rs;
    });

    setOginginSkillMatrixs(data);
    setSkillMatrixs(data);
    setPaging(pagingRq);
  };
  
  return (
    <Card>
      <Row style={{ padding: '10px' }}>
        <SkillMatrixTableHeader
          // toggelFilter={() => toggelFilter()}
          handleChange={(key, value) => {
            console.log(key,value)
            // if(key == 'keyword'){
              const uiParam = {...searchUIParam, [key]: value}
              console.log(uiParam)
              setSearchUIParam(uiParam)
              searchUI(uiParam)
            // }
            // else if(key == 'department'){
            //   const uiParam = {...searchUIParam, department: value}
            //   setSearchUIParam(uiParam)
            //   searchUI(uiParam)
            // }
          }}
          meetingId={meetingId}
          style={{ padding: '10px' }}
        />
        <Col lg={isFilter ? 9 : 12}>
          <SkillMatrixTable
            data={skillMatrixs}
            paging={paging}
            total={0}
            nextPage={() => {}}
            previousPage={() => {}}
            gotoPage={() => {}}
          />
        </Col>
        {/* {isFilter && (
          <Col lg={3}>
            <SkillMatrixFilter
              formData={filter}
              handleSubmit={handleSubmit}
            />
          </Col>
        )} */}
      </Row>
    </Card>
  );
};

RunningSkillMatrix.propTypes = {
  meetingId: PropTypes.string
};

export default RunningSkillMatrix;
